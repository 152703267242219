import React, { useContext, useState } from 'react';
import { ButtonCustom } from './ButtonCustom';
import { TextField, Grid, Typography, Box } from '@mui/material'
import { useMutation } from '@apollo/client'
import { AuthContext } from '../auth/AuthWrapper'
import { useNavigate } from 'react-router-dom'
import { LOGIN } from '../graphql/services/Auth';

const LoginForm = () => {
    const [formData, setFormData] = useState({ username: '', password: ''})
    const [loginUser, { loading }] = useMutation(LOGIN)
    const { login } = useContext(AuthContext)
    const [error, setError] = useState(null)

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }
    
    const Spacer = ({ height }) => {
        return <div style={{ height: height }} />;
    }

    const handleSubmit = async (e) => {
        try {
          const res = await loginUser({
            variables: { 
                username: formData.username,
                password: formData.password
            }
          })
          
          if(res.data.login.statusCode === 200) {
            login(res.data.login.token)
          } else {
            const errorMessage = res.data.login.message
            
            switch (errorMessage) {
                case 'Pengguna atau Password Salah!':
                    setError('Pengguna atau kata sandi salah!')
                    break
                case 'Kesalahan Password 3x! Akun anda terkunci lakukan reset password!':
                    setError('Kesalahan kata sandi 3x! Akun anda terkunci lakukan reset kata sandi!')
                    break
                case 'Password anda telah kedaluwarsa! lakukan reset password!':
                    setError('Kata sandi anda telah kedaluwarsa! lakukan reset kata sandi!')
                    break
                case 'Kesalahan autentikasi. Password salah!':
                    setError('Pengguna atau kata sandi salah!')
                    break
                case 'Akun anda tidak aktif, hubungi Administrator!':
                    setError('Akun anda tidak aktif, hubungi Administrator!')
                    break
            }
          }
          
        } catch (error) {
            if(error === 'Internal server error') {
                setError('Internal server error')
            }
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    }

    const renderError = () => {
        if (error === 'Pengguna atau kata sandi salah!' || 
            error === 'Kesalahan kata sandi 3x! Akun anda terkunci lakukan reset kata sandi!' ||
            error === 'Kata sandi anda telah kedaluwarsa! lakukan reset kata sandi!' ||
            error === 'Kesalahan autentikasi. Kata sandi salah!' ||
            error === 'Internal server error' ||
            error === 'Akun anda tidak aktif, hubungi Administrator!') {
            return error
        } else {
            return null;
        }
    }

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                spacing="15"
            >
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" 
                        sx={{
                            fontWeight: 500,
                            color: '#7E7E7E'
                        }}
                    >
                        ID Pengguna
                    </Typography>
                    <TextField
                        fullWidth
                        name="username"
                        variant="outlined"
                        size="small"
                        value={formData.username}
                        onChange={handleFieldChange}
                        sx={{
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                                borderColor: '#EAE6FF',
                                backgroundColor: '#EAE6FF',
                                borderRadius: '8px',
                                border: 'none',
                                '&:hover': {
                                    border: 'none',
                                    backgroundColor: '#DFDCF2',
                                }
                            },
                            '& .MuiInputLabel-outlined': {
                                color: '#EAE6FF',
                                borderRadius: '8px',
                                border: 'none',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#EAE6FF',
                                borderRadius: '8px',
                                border: 'none',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#DFDCF2',
                              border: 'none',
                            }
                        }}
                    />
                </Grid>
                <Spacer height="16px"/>
                <Grid 
                    item
                    container
                    direction="column"
                    spacing="8">
                    <Grid item>
                        <Typography variant="subtitle1" sx={{
                            fontWeight: 500,
                            color: '#7E7E7E'
                        }}>
                            Kata Sandi
                        </Typography>
                        <TextField
                            name="password"
                            variant="outlined"
                            size="small"
                            type="password"
                            value={formData.password}
                            onChange={handleFieldChange}
                            onKeyDown={handleKeyDown}
                            fullWidth
                            sx={{
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                                    borderColor: '#EAE6FF',
                                    backgroundColor: '#EAE6FF',
                                    borderRadius: '8px',
                                    border: 'none',
                                    '&:hover': {
                                        border: 'none',
                                        backgroundColor: '#DFDCF2',
                                    }
                                },
                                '& .MuiInputLabel-outlined': {
                                    color: '#EAE6FF',
                                    borderRadius: '8px',
                                    border: 'none',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#EAE6FF',
                                    borderRadius: '8px',
                                    border: 'none',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#DFDCF2',
                                border: 'none',
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                {
                    renderError() != null ? 
                    <Grid item justifyContent="flex-end">
                        <Box
                            sx={{
                                bgcolor: '#F9BABA',
                                borderRadius: 1,
                                border: '2px solid #FC5E5E',
                                padding: 1
                            }}
                        >
                            { renderError() }
                        </Box>
                    </Grid> : <></>
                }
                
                <Grid item
                    textAlign="center"
                >  
                    <Spacer height="53px"/>
                    <ButtonCustom data={"MASUK"} status={"login"} onClick={handleSubmit} loading={loading} />
                </Grid>

            </Grid>
        </>
        
    )
}

export default LoginForm
