import React, { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Subnavbar from "../components/Subnavbar";
import { Sidebar } from "../components/Sidebar";
import { Typography, Grid, Box } from "@mui/material";
import Content from "../components/Content";
import { useLocation } from "react-router-dom";

export const Monitoring = ({ user, menu, allMenus }) => {
  const location = useLocation()
  const [openMenus, setOpenMenus] = useState({});
  const [last, setLast] = useState("0");
  const [pathName, setPathName] = useState(
    window.location.hash.slice(1)
  );

  const handleMenuClick = (menuId, pathName, isLast) => {
    setPathName(pathName);
    if (isLast === "1") {
      setLast(isLast);
      var splittedPathName = pathName.toLowerCase().split(" > ");
      window.history.pushState(
        null,
        "",
        `/monitoring#${splittedPathName[splittedPathName.length - 1]}`
      );
    }
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [menuId]: !prevOpenMenus[menuId],
    }));
  };

  return (
    <div>
      <Navbar user={user} />
      <Subnavbar user={user} allMenus={allMenus} />
      <div
        style={{
          marginTop: 109,
          padding: "16px 21px 16px 21px",
          alignItems: "left",
          height: '100%',
        }}
      >
        <Typography sx={{ fontSize: 12, fontWeight: 500, paddingLeft: "20px" }}>
          {pathName}
        </Typography>
        <Grid container>
          <Grid
            item
            xs={2.4}
            sx={{
              overflowY: "scroll",
              overflowX: "hidden",
              marginTop: "15px",
              height: "78vh",
              "&::-webkit-scrollbar": {
                width: "0.1em",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#E0DFDF",
                borderRadius: "6px",
                outline: "1px solid #E0DFDF",
              },
            }}
          >
            <Sidebar
              menu={location.state.route || menu}
              openMenus={openMenus}
              handleMenuClick={handleMenuClick}
            />
          </Grid>
          <Grid
            item
            xs={9.5}
            sx={{
              marginTop: "15px",
              height: '78vh',
              overflowX: "auto",
              marginLeft: "10px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4em",
                overflowY: "auto",
                display: "block",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#908E8E",
                borderRadius: "6px",
                outline: "none",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#FCFAFA",
                borderRadius: 2,
                height: 'auto',
                marginBottom: '40px',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ fontSize: 13, margin: "0" }}>
                <Content
                  isLast={last}
                  path={pathName}
                  user={user}
                  type={"table"}
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};
