import React, { useContext, useEffect } from 'react'
import { useNavigate, useLocation  } from 'react-router-dom'
import { AuthContext } from '../auth/AuthWrapper'
import { Transaksi } from '../pages/Transaksi';

export const TransaksiContainer = () => {
    const { authState } = useContext(AuthContext);
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (!authState.token || !authState.user) {
            navigate('/login');
        }
    }, [authState, navigate])

    return <Transaksi user={authState.user} menu={location.state.data} allMenus={location.state.allMenus}/>
}
