import { gql, useQuery } from "@apollo/client";

export const GET_MENU = gql`
    query GetMenuByUser {
        getMenuByUser {
            statusCode
            message
            result {
                level_id
                parent_menu
                menu_id
                menu_seq
                name
                path_name
                is_last
                submenus {
                    level_id
                    parent_menu
                    menu_id
                    menu_seq
                    name
                    path_name
                    is_last
                    submenus {
                        level_id
                        parent_menu
                        menu_id
                        menu_seq
                        name
                        path_name
                        is_last
                        submenus {
                            level_id
                            parent_menu
                            menu_id
                            menu_seq
                            name
                            path_name
                            is_last
                        }
                    }
                }
            }
        }
    }
`

export const useGetMenu = () => {
    return useQuery(GET_MENU)
}