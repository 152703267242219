import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { MenuItem } from './MenuItem';
  
export const Sidebar = ({ menu, openMenus, handleMenuClick }) => {
    const [clicked, setClicked] = useState(null)

    const handleMenuItemClick = (menuId, path_name, isLast) => {
        setClicked(menuId)
        handleMenuClick(menuId, path_name, isLast)
    }

    return (
        <Box
            sx={{
                padding: '15px 15px 5px 15px',
                backgroundColor: '#F6F1F1',
                borderRadius: '5px'
            }}
        >
            <Typography 
                sx={{
                    fontSize: 13,
                    fontWeight: 700,
                }}
            >
                {menu.name}
            </Typography>
            {
                menu.submenus.map((i) => {
                    return (
                        <MenuItem
                            key={i.menu_id}
                            subMenu={i}
                            isOpen={!!openMenus[i.menu_id]}
                            handleClick={handleMenuItemClick}
                            openMenus={openMenus}
                            clicked={clicked}
                        />
                    )
                })
            }
        </Box>
    )
}
