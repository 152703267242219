import React from 'react'
import { Typography, createTheme, ThemeProvider, Paper, Box, Grid } from '@mui/material'
import logo from '../assets/image/login_bg.png'
import LoginForm from '../components/LoginForm';

const Login = () => {
    const theme = createTheme({
        typography: {
          fontFamily: 'Open sans, sans-serif'
        },
    });

    const Spacer = ({ height }) => {
        return <div style={{ height: height }} />;
      };

    return (
        <>
            <Box
                sx={{
                    color: 'white',
                    background: 'linear-gradient(to right bottom, #7054E4, #A562CE)',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Paper 
                    elevation={0}
                    sx={{
                        width: '1100px',
                        height: '546px',
                        borderRadius: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}    
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                    >
                        <Grid
                            item
                            container
                            direction="column"
                            xs={7}
                            sx={{
                                position: 'relative',
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'start',
                                height: '546px',
                                backgroundImage: `url(${logo}), linear-gradient(to right bottom, #7B6CEB, #C474AE)`,
                                backgroundSize: 'cover, cover',
                                backgroundRepeat: 'no-repeat, no-repeat',
                                backgroundPosition: 'center, center',
                            }}
                        >
                            <Box>
                                <Typography align="center"
                                    sx={{
                                        fontWeight: 650,
                                        marginTop: '80px',
                                        fontSize: '58px'
                                    }}>
                                        COMODO
                                </Typography>
                            
                                <Typography align="center"
                                    sx={{
                                        fontWeight: 600,
                                        marginTop: '10px',
                                        fontSize: '25px'
                                    }}>
                                        Consignment Monitoring Nusindo
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid 
                            item
                            xs={5}
                            sx={{
                                padding: '81px 60px 0px 60px',
                                height: '526px',
                            }}
                        >
                            <ThemeProvider theme={theme}>
                                <Typography align="center" 
                                    sx={{
                                        fontWeight: 700,
                                        color: '#A789DF',
                                        fontSize: '28px'
                                    }}
                                >
                                    MASUK
                                </Typography>
                            </ThemeProvider>
                            <Spacer height="53px"/>
                            <LoginForm />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </>
    )
}

export default Login