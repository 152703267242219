import { gql, useLazyQuery, useQuery } from '@apollo/client'

export const GET_SESSION = gql`
    query GetSessionUser {
        getSessionUser {
            statusCode
            message
            result {
                username
                password
                email
                nama
                role_id
                supplier_id
                supplier_name
                branch_id
                branch_name
                branch_address
                is_active
            }
        }
    }
`

export const useUserSession = () => {
    const [fetchUserSession, { data, loading, error }] = useLazyQuery(GET_SESSION)
    return { fetchUserSession, data, loading, error }
}