import React from 'react'
import { Grid, Typography } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

export const MenuItem = ({subMenu, handleClick, isOpen, openMenus, depth = 0, clicked }) => {

    const handleSubmenuClick = (e) => {
        e.stopPropagation()
        handleClick(subMenu.menu_id, subMenu.path_name, subMenu.is_last)
    }

    const isClicked = clicked === subMenu.menu_id
    const hasClickedChild = subMenu.submenus && subMenu.submenus.some(child => child.menu_id === clicked)

    return (
        <>
            <Grid container direction='row' onClick={handleSubmenuClick} 
                sx={{ 
                    paddingLeft: subMenu.level_id === '4' ? `${depth * 25}px` : `${depth * 30}px`, 
                    '&:hover': { cursor: 'pointer' },
                    alignItems: 'center'
                }}
            >
                {subMenu.is_last === '0' && (
                    <Grid item sx={{ color: '#989898' }} xs={1.5}>
                        <ArrowRightIcon
                            sx={{
                                transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                                transition: 'transform 0.3s ease'
                            }}
                        />
                    </Grid>
                )}
                <Grid item xs={subMenu.is_last === '0' ? 10 : 12}>
                    <Typography 
                        sx={{ 
                            fontSize: 13, 
                            fontWeight: subMenu.level_id === '3' || isClicked || hasClickedChild? 700 : 400, 
                            margin: subMenu.is_last === '0' ? '' : (subMenu.level_id === '4' ? '5px 0 5px 10px' : '5px 0 5px 0')
                        }}
                    >
                        {subMenu.name.includes('Dtms') ? subMenu.name.replace(/Dtms/g, 'DTMS') : subMenu.name}
                    </Typography>
                </Grid>
            </Grid>
            {isOpen && subMenu.submenus && subMenu.submenus.map((childSubMenu) => (
                <MenuItem
                    key={childSubMenu.menu_id}
                    subMenu={childSubMenu}
                    handleClick={handleClick}
                    isOpen={!!openMenus[childSubMenu.menu_id]}
                    openMenus={openMenus}
                    depth={depth + 1}
                    clicked={clicked}
                />
            ))}
        </>
    )
}