import React, { useContext, useState } from 'react'
import { AppBar, Container, Typography, Grid, Box, Toolbar, Menu, MenuItem, Divider, IconButton, Avatar, TextField, InputAdornment, FormControl, InputLabel, Input } from '@mui/material'
import Logo from '../assets/image/logo-simplified.png'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { AuthContext } from '../auth/AuthWrapper'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { LOGOUT } from '../graphql/services/Auth'
import { DefaultModal } from './Modal'
import { ButtonCustom } from './ButtonCustom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import extManual from '../assets/books/[EXT] Manual Book COMODO (Consignment Monitoring Nusindo).pdf'
import intManual from '../assets/books/[INT] Manual Book COMODO (Consignment Monitoring Nusindo).pdf'

const Navbar = ({user}) => {
    const settings = ['Ubah Kata Sandi', 'Buku Panduan', 'Bantuan']
    const navigate = useNavigate()

    const { logout, authState } = useContext(AuthContext)
    const [logoutUser] = useMutation(LOGOUT)

    const [anchorElUser, setAnchorElUser] = useState(null)
    const [open, setOpen] = useState(false)

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }
    const [showPassword, setShowPassword] = useState(false)
    const [showReType, setReType] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false)
  
    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show)
    const handleClickShowReType = () => setReType((show) => !show)
    const handleClickShowPassword = () => setShowPassword((show) => !show)

    const [formData, setFormData] = useState({
        oldPassword: '',
        reOldPass: '',
        newPassword: ''
    })

    const getInitials = (nama) => {
        const allNames = nama.trim().split(' ')
        const initials = allNames.reduce((acc, curr, index) => {
          if(index === 0 || index === allNames.length - 1){
            acc = `${acc}${curr.charAt(0).toUpperCase()}`
          }
          return acc
        }, '')
        return initials
    }

    const handleHomeNavigate = () => {
        navigate('/beranda')
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const handleMenuItem = async (event, type) => {
        if (type === 'keluar') {
            const token = localStorage.getItem('token');
        
            try {
              const res = await logoutUser({
                headers: {
                  authorization: `Bearer ${token}`,
                },
              });
              if (res.data.logout.statusCode === 200) {
                logout();
                navigate('/login')
              }
            } catch (error) {
              console.error("Logout Mutation Error:", error); 
            }
        } 
        if ( type === 2) {
            if(user.supplier_id === null) {
                return window.open('https://portal.nusindo.co.id/nhts/login', "_blank", "noreferrer")
            } else {
                const phoneNumber = '6282220953355'
                const message = `Halo! Saya ${user.nama} dari ${user.supplier_name} ingin bertanya mengenai aplikasi Comodo.`
                const encodedMessage = encodeURIComponent(message)
                const waLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`

                window.location.href = waLink 
            }
        }
        if(type === 1) {
            const link = document.createElement('a');
            link.href = authState.user.role_id === '5' ? extManual : intManual;
            link.download = 'Manual Book COMODO (Consignment Monitoring Nusindo).pdf'; 
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if( type === 0) {
            setOpen(true)
        }
        handleCloseUserMenu();
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar 
                elevation={0}
                sx={{
                    position: 'fixed',
                    background: 'linear-gradient(to right, #7154E3, #C073B1)',
                    color: '#FFDC53',
                    height: 72,
                    justifyContent: "center"
                }}
            >
                <Toolbar>
                    <Container sx={{ marginLeft: 0,}}>
                        <Grid 
                            container
                            direction="row"
                            spacing="10"
                            width='400px'
                            sx={{
                                marginLeft: '0'
                            }}
                        >
                            <Grid item 
                                xs={2}
                                onClick={handleHomeNavigate}
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer'
                                    },
                                }}
                            >
                                <img src={Logo} alt="COMODO" style={{ width: 182 }}/>
                            </Grid>
                            <Grid item container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems='start'
                                xs={10}
                                onClick={handleHomeNavigate}
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer'
                                    },
                                }}
                            >
                            </Grid>
                        </Grid>
                    </Container>
                    
                    <Box sx={{ flexGrow: 1 }} />

                    <Box
                        sx={{ display: { xs: 'none', md: 'flex' } }}
                    >
                        <Container
                            disableGutters
                            sx={{
                                width: 400,
                            }}
                        >
                            <Typography sx={{ fontWeight: 700, fontSize: '13px', textAlign: 'end', color: 'white' }}>
                                {user.nama.toUpperCase()}
                            </Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: '13px', textAlign: 'end', color: 'white' }}>
                                { user.username }
                            </Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: '13px', textAlign: 'end', color: 'white' }}>
                                { user.supplier_id === null ? `${user.branch_id} - ${user.branch_name}` :  `${user.supplier_id} - ${user.supplier_name}`}
                            </Typography>
                        </Container>

                        <IconButton onClick={handleOpenUserMenu} sx={{ color: 'white', paddingLeft: '8px', marginRight: 0 }}>
                            <ArrowDropDownIcon/>
                        </IconButton>

                        <Avatar sx={{ width: 50, height: 50, fontWeight: 600}} >
                            {getInitials(user.nama)} 
                        </Avatar>
                        
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            >
                            {settings.map((setting, i) => (
                                <MenuItem key={setting} onClick={(e) => handleMenuItem(e, i)}>
                                    <Typography textAlign="center" sx={{fontSize: '14px', fontWeight: 500}}>{setting}</Typography>
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem key={'Keluar'} onClick={(e) => handleMenuItem(e, 'keluar')}>
                                <Typography textAlign="center" sx={{fontSize: '14px', fontWeight: 500}}>Keluar</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>

                    
                </Toolbar>
            </AppBar>
            <DefaultModal
                open={open}
                size={'default'}
                title={'UBAH KATA SANDI'}
                description={
                    <>
                        <Grid container>
                            <Grid item container direction="row" xs={12} sx={{ marginBottom: '20px' }}>
                                <Grid item xs={4} sx={{ marginTop: '4px' }}>
                                    Kata sandi lama<span style={{ color: 'red' }}>*</span>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        name="password"
                                        variant="outlined"
                                        size="small"
                                        value={formData.password}
                                        onChange={(event) => {
                                            setFormData({
                                                password: event.target.value
                                            })
                                        }}
                                        type={showOldPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                onClick={handleClickShowOldPassword}
                                                >
                                                {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" xs={12} sx={{ marginBottom: '20px' }}>
                                <Grid item xs={4} sx={{ marginTop: '4px' }}>
                                    Ulangi kata sandi lama<span style={{ color: 'red' }}>*</span>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        name="reOldPass"
                                        variant="outlined"
                                        size="small"
                                        value={formData.reOldPass}
                                        onChange={(event) => {
                                            setFormData({
                                                reOldPass: event.target.value
                                            })
                                        }}
                                        type={showReType ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                onClick={handleClickShowReType}
                                                >
                                                {showReType ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" xs={12} sx={{ marginBottom: '20px' }}>
                                <Grid item xs={4} sx={{ marginTop: '4px' }}>
                                    Kata sandi baru<span style={{ color: 'red' }}>*</span>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        name="newPassword"
                                        variant="outlined"
                                        size="small"
                                        value={formData.newPassword}
                                        onChange={(event) => {
                                            setFormData({
                                                newPassword: event.target.value
                                            })
                                        }}
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                onClick={handleClickShowPassword}
                                                >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
                actions={[
                    <ButtonCustom data={'simpan'}disable={formData.oldPassword === '' || formData.reOldPass === '' || formData.newPassword === ''}/>,
                    <ButtonCustom data={'batal'} status={'cancel'} onClick={() => {
                        setOpen(false)
                        setFormData({
                            formData,
                            oldPassword: '',
                            reOldPass: '',
                            newPassword: ''
                        })
                    }} />
                ]}
            />
        </Box>
    )
}

export default Navbar