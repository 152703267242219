import { CircularProgress } from '@mui/material';
import React, { lazy, Suspense } from 'react'

const Content = ({ isLast, path, user, type }) => {
    const arr = path.toLowerCase().split(' > ')
    var arrSpace = []
    arr.forEach(i => {
        if(i.includes(' ')) {
            arrSpace.push(i.replace(/\s/g, ''))
        } else {
            arrSpace.push(i)
        }
    })
    
    const loadComponent = () => {
        if ((/[a-z]/).test(path) || '') {
            if(isLast === '1' || isLast === '2') {
                return import(`../content/${arrSpace[2]}/${arrSpace[arrSpace.length-1]}.js`).catch(() => import(`../content/ErrorPage.js`));
            }
        } else {
            return new Promise(resolve => resolve({ default: () => null }))
        }
    }

    const Component = lazy(loadComponent)
    
    return (
        <Suspense fallback={<div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '30%',
        }}>
            <CircularProgress />
        </div>}>
            <Component user={user} type={type}/>
        </Suspense>
    );
};

export default Content