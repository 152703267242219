import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../auth/AuthWrapper";
import { Monitoring } from "../pages/Monitoring";

export const MonitoringContainer = () => {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!authState.token || !authState.user) {
      navigate("/login");
    }
  }, [authState, navigate]);

  return (
    <Monitoring
      user={authState.user}
      menu={location.state.data}
      allMenus={location.state.allMenus}
    />
  );
};
