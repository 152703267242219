import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Grid } from '@mui/material'
import logo from '../assets/icon/gudang.png'

const MenuHome = ({ data, route, allMenus }) => {
    const navigate = useNavigate()

    const handleClick = async (event) => {
        navigate(`/${route.toLowerCase()}`, { state: {data, allMenus} })
    }

    const Spacer = ({ height }) => {
        return <div style={{ height: height }} />;
    }
    
    return (
        <>
            <Box
                onClick={handleClick}
                variant="contained"
                sx = {{
                    background: 'linear-gradient(to bottom, #7154E3, #C073B1)',
                    width: '327px',
                    height: '267px',
                    textAlign: 'center',
                    alignContent: 'center',
                    fontWeight: 600,
                    color: '#000000',
                    borderRadius: '20px',
                    '&:hover': {
                        backgroundColor: '#CACACA',
                        boxShadow: '2',
                        cursor: 'pointer'
                    },
                }}
            >     
                <Grid container direction="column">
                    <Grid item>
                        <img src={require(`../assets/icon/${data.name.toLowerCase()}.png`)} alt={data.name} style={{ width: 100 }}/>
                    </Grid>
                    <Spacer height="30px"/>
                    <Grid item
                        sx={{
                            color: 'white',
                            fontSize: 19
                        }}
                    >
                        {data.name.toUpperCase()}
                    </Grid>
                </Grid>
            </Box>
        </>
        
    )
}

export default MenuHome