import React from 'react'
import { AppBar, Container, Typography, Grid, Box, Toolbar } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'

const Subnavbar = ({ menu, allMenus }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const handleHomeNavigate = () => {
        navigate('/beranda')
    }

    const handleSubnavNavigate = (route) => {
        if(route.name.toLowerCase() !== location.pathname.substring(1)) {
            navigate(`/${route.name.toLowerCase()}`, {state: {route, allMenus}})
        }
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar 
                elevation={0}
                sx={{
                    position: 'fixed',
                    background: 'linear-gradient(to right, #7154E3, #C073B1)',
                    color: 'white',
                    height: 37,
                    justifyContent: "center",
                    marginTop: '75px'
                }}
            >
                <Toolbar>
                    <Container sx={{ marginLeft: 0}}>
                        <Grid 
                            container
                            direction="row"
                            spacing={3}
                            sx={{
                                marginLeft: '0',
                                width: '800px'
                            }}
                        >
                            <Grid item key={'BERANDA'}>
                                <Typography 
                                    sx={{ 
                                        fontWeight: 600, 
                                        fontSize: '13px', 
                                        textAlign: 'start', 
                                        color: 'white',
                                        '&:hover': {
                                            cursor: 'pointer'
                                        }
                                    }}
                                    onClick={handleHomeNavigate}
                                >
                                    BERANDA
                                </Typography>
                            </Grid>
                            {
                                allMenus.submenus.map((i) => {
                                    return (
                                        <Grid item key={i.name}>
                                            <Typography 
                                                sx={{ 
                                                    fontWeight: 600, 
                                                    fontSize: '13px', 
                                                    textAlign: 'start', 
                                                    color: 'white',
                                                    '&:hover': {
                                                        cursor: 'pointer'
                                                    }
                                                }}
                                                key={i.name}
                                                onClick={() => handleSubnavNavigate(i)}
                                            >
                                                {i.name.toUpperCase()}
                                            </Typography>
                                            {i.name.toLowerCase() === location.pathname.substring(1) ? <Box sx={{
                                                    width: "100%`",
                                                    marginTop: '2.9px',
                                                    height: '2.5px',
                                                    backgroundColor: 'white',
                                                    borderRadius: 4
                                                }}
                                            /> : <></>}
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Container>
                    
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Subnavbar